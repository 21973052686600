import React from "react";
import {
  CardWrapper,
  Container,
  Content,
  FirstText,
  Number,
  SecondText,
  Text,
  TextsWrapper,
  ThirdText,
  Title,
  Wrapper,
} from "./SectionOneElements";

import { Typography } from "@meiuca/jota-design-system-react-team-lps";

const SectionOne = () => {
  return (
    <Container>
      <Content>
        <Wrapper>
          <TextsWrapper>
            <FirstText>
              <Typography component="paragraph" size="medium">
                O objetivo dessa Declaração de Privacidade é explicar como nós
                ("Meiuca Design" ou "nós") coletamos e utilizamos os seus dados
                ("você"), explicando os motivos e finalidades da coleta. O que
                explicamos aqui vale para os conteúdos que desenvolvemos e
                apresentamos, de forma autoral, disponíveis no link de acesso{" "}
                <a target="_blank" rel="noreferrer" href="http://meiuca.co">
                  https://meiuca.co
                </a>{" "}
              </Typography>
            </FirstText>
            <SecondText>
              <Typography component="paragraph" size="medium">
                As práticas aqui descritas estão de acordo com a legislação
                vigente no Brasil e fora dele, principalmente com a Lei Geral de
                Proteção de Dados (13.709/2018) do Brasil e a General Data
                Protection Regulation da União Europeia (2016).
              </Typography>
            </SecondText>
            <ThirdText>
              <Typography component="paragraph" size="medium">
                É muito importante que você leia esse documento inteiro!
              </Typography>
            </ThirdText>
          </TextsWrapper>
          <CardWrapper>
            <Number>01</Number>
            <Title>Sobre o seu consentimento</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Você aceita essa Declaração quando acessar ou interagir com o
                site institucional "meiuca.co". Dessa forma, você estará ciente
                e em total acordo com o modo como utilizaremos as suas
                informações e os seus dados pessoais. Caso não concorde com esta
                Declaração, por favor, não continue utilizando o site
                "meiuca.co".
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>02</Number>
            <Title>Quais são os dados pessoais que coletamos de você?</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Ao navegar pelas páginas do site "meiuca.co", coletamos seu IP,
                data e hora de acesso, cookies, fonte de referência, tipo de
                navegador, duração da visita, páginas visitadas, geolocalização
                e informações de identificação do navegador e do sistema
                operacional. Esses dados pessoais são coletados e armazenados
                por nós da Meiuca Design e são utilizados apenas nas hipóteses
                que explicamos aqui nesta Declaração.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>03</Number>
            <Title>O que são cookies?</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Cookies são arquivos criados pelos sites que você visita e que
                são salvos no seu navegador de Internet. Esses arquivos contém
                informações que servem para identificar que é você que está
                navegando pelos sites que você visita. Alguns cookies possuem
                data de expiração, enquanto outros ficam armazenados por mais
                tempo no seu navegador.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>04</Number>
            <Title>Por que coletamos esses dados pessoais?</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Utilizamos os dados pessoais coletados para melhorar a sua
                experiência utilizando nossos serviços. Com informações mais
                precisas, conseguimos prestar serviços da forma mais exclusiva e
                personalizada possível.
                <br />
                <br /> Além disso, a utilização dos seus dados pessoais também
                são importantes para:
                <br />
                <br /> 1. Aumentar a confiabilidade dos serviços e projetos que
                elaboramos;
                <br /> 2. Melhorar o atendimento, a experiência e o suporte que
                te oferecemos;
                <br /> 3. Desenvolver novos projetos, produtos e serviços;{" "}
                <br />
                4. Aperfeiçoar estratégias como marketing digital dos nossos
                produtos e serviços.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>05</Number>
            <Title>O que fazemos com os dados pessoais coletados?</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Para alcançar os objetivos que falamos no tópico anterior,
                utilizamos seus dados pessoais, de forma anônima, para
                realização de pesquisas da área de marketing.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>06</Number>
            <Title>
              Podemos excluir seus dados pessoais armazenados a qualquer momento
            </Title>
            <Text>
              <Typography component="paragraph" size="small">
                Para isso, basta apenas entrar em contato conosco pelo e-mail{" "}
                <a href="mailto:oi@meiuca.design">oi@meiuca.design.</a>
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>07</Number>
            <Title>
              Nosso compromisso com a segurança dos seus dados pessoais
            </Title>
            <Text>
              <Typography component="paragraph" size="small">
                Nós da Meiuca Design coletamos esses dados pessoais por
                finalidades legítimas; compatíveis com o que informamos a você
                aqui nesta Declaração; tratando somente o essencial para o site;
                garantindo a clareza e integridade; tomando todos os cuidados e
                utilizando todos os mecanismos necessários para garantir a
                proteção dos dados pessoais armazenados; e prezando pela tomada
                de medidas preventivas e não discriminatórias. Nunca, em
                hipótese alguma, iremos divulgar, transferir, ceder, alienar ou
                repassar os dados pessoais que você compartilha conosco, por
                qualquer motivo ou justificativa.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>08</Number>
            <Title>
              Nosso compromisso com a Lei Geral de Proteção de Dados
            </Title>
            <Text>
              <Typography component="paragraph" size="small">
                Nossa Declaração de Privacidade foi criada com base na Lei Geral
                de Proteção de Dados (13.709/2018) do Brasil e a General Data
                Protection Regulation da União Europeia (2016). Sendo assim,
                seguimos os fundamentos:
                <br />
                <br /> a) Respeito à privacidade; <br />
                b) Autodeterminação informativa;
                <br /> c) Liberdade de expressão, de informação, de comunicação
                e de opinião;
                <br /> d) Inviolabilidade da intimidade, da honra e da imagem;
                <br /> e) Desenvolvimento econômico e tecnológico e a inovação;{" "}
                <br />
                f) Livre iniciativa, a livre concorrência e a defesa do
                consumidor;
                <br /> g) Direitos humanos, o livre desenvolvimento da
                personalidade, a dignidade e o exercício da cidadania pelas
                pessoas naturais.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>09</Number>
            <Title>Nosso compromisso com a legislação vigente</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Todos os itens desta Declaração de Privacidade estão regidos
                pelas leis vigentes na República Federativa do Brasil e ao
                compilado de legislações internacionais, tratados internacionais
                e legislação esparsa, com a finalidade de garantir a maior
                segurança possível.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>10</Number>
            <Title>Sobre o foro</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Para todos os assuntos referentes à sua interpretação e
                cumprimento, a Meiuca Design está e estará sempre à disposição
                da{" "}
                <b>
                  Autoridade Nacional de Proteção de Dados, do Conselho Nacional
                  de Proteção de Dados Pessoais e Privacidade
                </b>
                , elegendo o Foro de São Paulo, Estado de São Paulo, como
                jurisdição competente para qualquer conflito de interesses ou
                pendência.
              </Typography>
            </Text>
          </CardWrapper>
          <CardWrapper>
            <Number>11</Number>
            <Title>Ficou com alguma dúvida?</Title>
            <Text>
              <Typography component="paragraph" size="small">
                Fique à vontade para entrar em contato conosco pelo e-mail{" "}
                <a href="mailto:oi@meiuca.design">oi@meiuca.design.</a>
              </Typography>
            </Text>
          </CardWrapper>
        </Wrapper>
      </Content>
    </Container>
  );
};

export default SectionOne;
