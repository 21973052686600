import React from "react";
import Grid from "../../components/Grid";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { HomePageContainer } from "./HomeElements";
import "../../App.css";
import Hero from "../../sections/home/Hero";
import SectionOne from "../../sections/home/SectionOne";

const Home = () => {
  return (
    <HomePageContainer>
      <Grid />
      <Header />
      <Hero />
      <SectionOne />
      <Footer />
    </HomePageContainer>
  );
};

export default Home;
