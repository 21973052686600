import styled from "styled-components";

export const FooterContainer = styled.footer`
  margin-top: -8px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    margin-top: -16px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: -24px;
    padding: 0 24px;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  line-height: 133%;
  gap: 15.33px;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
  }
`;

export const MeiucaIcon = styled.img`
  display: block;
  width: 10px;
  height: 14px;
`;
