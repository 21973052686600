import styled from "styled-components";
import { motion } from "framer-motion";

export const HomePageContainer = styled(motion.div)`
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 24px;
  }
`;
