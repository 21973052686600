import styled from "styled-components";

export const Container = styled.section`
  background-color: #fff;
  color: #000;
  margin-top: -8px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: -16px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: -24px;
  }
`;

export const Content = styled.div`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-column-gap: 8px;
  width: 100%;
  padding: 0 16px;
  max-width: 1440px;
  margin: 80px auto;

  @media (min-width: 768px) {
    margin: 120px auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    grid-column-gap: 24px;
    padding: 0 24px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    grid-column: 2 / 12;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
  }
`;

export const TextsWrapper = styled.div`
  border-bottom: 4px solid #000;

  @media screen and (min-width: 768px) {
    grid-column: 1 / 11;
  }
`;

export const FirstText = styled.div`
  max-width: 978px;
  margin-bottom: 30px;

  a {
    color: #000000a0;
    text-decoration: underline;

    &::selection {
      color: #fff;
      background-color: #000;
    }
  }
`;

export const SecondText = styled.div`
  max-width: 978px;
  margin-bottom: 30px;
`;

export const ThirdText = styled.div`
  max-width: 978px;
  margin-bottom: 80px;
`;

export const CardWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 40px;

  &:not(:last-of-type) {
    border-bottom: 2px solid #000;
  }

  @media only screen and (min-width: 768px) {
    grid-column: 1 / 11;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    margin-top: 24px;
    padding-bottom: 64px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #000;
    }
  }

  @media screen and (min-width: 1024px) {
    grid-column-gap: 24px;
  }
`;

export const Number = styled.h2`
  font-size: 40px;
  line-height: 100%;
  font-weight: 400;
  margin-bottom: 24px;

  &::selection {
    color: #fff;
    background-color: #000;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1 / 2;
    margin-bottom: 0;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  margin-bottom: 16px;
  max-width: 240px;

  &::selection {
    color: #fff;
    background-color: #000;
  }

  @media screen and (min-width: 768px) {
    grid-column: 3 / 6;
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  a {
    color: #000000a0;
    text-decoration: underline;

    &::selection {
      color: #fff;
      background-color: #000;
    }
  }

  b {
    font-weight: 700;

    &::selection {
      color: #fff;
      background-color: #000;
    }
  }

  @media screen and (min-width: 768px) {
    grid-column: 6 / 10;
  }
`;
