import styled from "styled-components";

export const HeroContainer = styled.section`
  background-color: #161313;
  margin-top: -8px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: -16px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: -24px;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 8px;
  width: 100%;
  padding: 0 16px;
  max-width: 1440px;
  margin: 80px auto 64px auto;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 24px;
    padding: 0 24px;
    margin: 200px auto;
  }
`;

export const HeroWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    grid-column: 2 / 12;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
  }
`;

export const HeroHeadingContent = styled.div`
  @media screen and (min-width: 768px) {
    grid-column: 1 / 11;
  }
`;

export const HeroHeadingWrapper = styled.div`
  position: relative;
  margin: 0 0 80px 0;
  max-width: 400px;

  @media screen and (min-width: 768px) {
    margin: 0;
  }

  @media screen and (min-width: 1024px) {
    max-width: 750px;
  }
`;

export const HeroHeadingMobile = styled.div`
  @media screen and (min-width: 1111px) {
    display: none;
  }
`;

export const HeroHeadingDesktop = styled.div`
  display: none;

  @media screen and (min-width: 1111px) {
    display: block;
  }
`;

export const HeroSelo = styled.img`
  top: -25px;
  width: 80px;
  height: 80px;
  right: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const HeroSeloDesktop = styled.img`
  top: 0;
  right: 0;
  width: 160px;
  height: 160px;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const HeroTextWrapper = styled.div`
  @media screen and (min-width: 768px) {
    grid-column: 1 / 11;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
  }

  @media screen and (min-width: 1111px) {
    margin-top: -110px;
  }
`;

export const HeroText = styled.div`
  max-width: 450px;

  @media screen and (min-width: 768px) {
    grid-column: 8 / 11;
  }
`;
