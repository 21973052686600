import { Typography } from "@meiuca/jota-design-system-react-team-lps";
import React from "react";
import meiucaIconLogo from "../../images/meiucaIconLogo.svg";
import { FooterContainer, FooterContent, MeiucaIcon } from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <MeiucaIcon src={meiucaIconLogo} />
        <Typography component="caption">
          © 2022 Meiuca – Todos os direitos reservados.
        </Typography>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
