import styled from "styled-components";

export const HeroGrid = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 8px;
  grid-auto-rows: minmax(100%, auto);
  width: calc(100% - 32px);
  height: 0;
  background: transparent;
  padding: 0;

  @media (min-width: 768px) {
    grid-gap: 16px;
    max-width: 1392px;
    margin: 0 auto;
    display: grid;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 24px;
    width: calc(100% - 48px);
    left: auto;
    right: auto;
  }
`;

export const GridP = styled.div`
  background: #f553;
  margin: 0;
  z-index: 1000;
`;
