import styled from "styled-components";

export const HeaderContainer = styled.header`
  background-color: #161313;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  height: 96px;

  @media screen and (min-width: 1024px) {
    padding: 0 24px;
    height: 88px;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MeiucaLogoOnColor = styled.img`
  width: 78px;
  height: 22px;
  display: block;
  position: relative;

  &::selection {
    background-color: transparent;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 32px;
  }
`;
