import "../../App.css";
import logoMeiucaOnColor from "../../images/logoMeiucaOnColor.svg";
import {
  HeaderContainer,
  HeaderContent,
  HeaderWrapper,
  MeiucaLogoOnColor,
} from "./HeaderElements";

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderWrapper>
          <MeiucaLogoOnColor
            tabIndex="0"
            alt="Logo da Meiuca"
            src={logoMeiucaOnColor}
          />
        </HeaderWrapper>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
