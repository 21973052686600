import React from "react";
import { GridP, HeroGrid } from "./GridElements";

const Grid = () => {
  document.addEventListener("keypress", function (e) {
    const grid = document.getElementById("grid");
    if (e.key === "+") {
      grid.style.height = "100%";
    } else if (e.key === "-") {
      grid.style.height = "0";
    }
  });

  return (
    <>
      <HeroGrid id="grid">
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
        <GridP></GridP>
      </HeroGrid>
    </>
  );
};

export default Grid;
