import { Typography } from "@meiuca/jota-design-system-react-team-lps";
import React from "react";
import {
  HeroContainer,
  HeroContent,
  HeroHeadingContent,
  HeroHeadingDesktop,
  HeroHeadingMobile,
  HeroHeadingWrapper,
  HeroSelo,
  HeroSeloDesktop,
  HeroText,
  HeroTextWrapper,
  HeroWrapper,
} from "./HeroElements";
import seloMeiucaCo from "../../../images/seloMeiucaCoOnColor.svg";
import seloMeiucaCoDesktop from "../../../images/seloMeiucaCoDesktopOnColor.svg";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroWrapper>
          <HeroHeadingContent>
            <HeroHeadingWrapper>
              <HeroHeadingMobile tabIndex="0">
                <Typography onColor size="x-large">
                  Declaração
                  <br />
                  de Privacidade
                </Typography>
              </HeroHeadingMobile>
              <HeroHeadingDesktop tabIndex="0">
                <Typography onColor size="display">
                  Declaração
                  <br />
                  de Privacidade
                </Typography>
              </HeroHeadingDesktop>
              <div className="seloMeiucaCo is-animating">
                <HeroSelo src={seloMeiucaCo} />
                <HeroSeloDesktop src={seloMeiucaCoDesktop} />
              </div>
            </HeroHeadingWrapper>
          </HeroHeadingContent>
          <HeroTextWrapper>
            <HeroText>
              <Typography onColor component="paragraph" size="medium">
                Nós da Meiuca acreditamos que a segurança e a privacidade das
                pessoas na Internet são fundamentais.
              </Typography>
            </HeroText>
          </HeroTextWrapper>
        </HeroWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
